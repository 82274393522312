import React, { useState } from "react"
import PropTypes from "prop-types"

import "./photoscroller.css"

const PhotoScroller = ({ prefix, extension, start = 0, length, folder, small }) => {

  let images = []
  const [indexToShow, setIndexToShow] = useState(start)

  for ( let i = start; i < length; i++ ) {
  	let index = i < 10 ? "0"+i : i 
  	let imgClass = i === start ? "img-first" : "img-scroller"
  	imgClass += i === indexToShow ? "" : " hidden"
  	images.push(<img key={i} className={imgClass} src={folder+"/"+prefix+index+"."+extension} alt={prefix+index} />)
  }

  function handleMouseMove (e) {
    setIndexToShow( Math.max(start, Math.floor((parseInt(length) * (e.clientX - 140) ) / e.target.offsetWidth)) )
  }

  return (
    <div className={`photoscroller ${small ? 'small':''}`} onMouseMove={handleMouseMove} role="presentation">
      {images}
    </div>
  )
}

PhotoScroller.propTypes = {
  prefix: PropTypes.string,
  extension: PropTypes.string,
  folder: PropTypes.string,
  length: PropTypes.number
}

export default PhotoScroller
