import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import PhotoScroller from "../components/PhotoScroller"

const BookPage = () => {

  let lv = []
  let lv_label = ['','IR','DFO','SFO','DM','DSFC','DFCD','SFCS','DFCS','SFCD']

  for ( let i = 0; i < 10; i++ ) {
    lv.push(
      <div key={i}>
        <div className="p1">
          Letterform Variations 0{i} {lv_label[i]}
        </div>
        <img src={`/lv/LV0${i}B.jpg`} alt={`Letterform Variations 0${i} - Bold`} />
        <img src={`/lv/LV0${i}L.jpg`} alt={`Letterform Variations 0${i} - Light`} />
      </div>
    )
  }

  return (
    <Layout theme="light">
      <Seo title="Book - Letterform Variations - Nigel Cottier" />
      
      <div className="book-content">
        <div className="block-content">
          <h2>
            Book
          </h2>

          <PhotoScroller folder="/book" prefix="" start={1} length={52} extension="jpg" />

          <div className="p1">
            Letterform Variations is a book by designer Nigel Cottier, Published by Slanted.
          </div>
          <div className="p1">
            692 pages containing 19,840 letters, all derived from one simple framework. Letterform Variations is a playful study into letterform construction using basic grid and shape based systems, and its potential to generate vast amounts of varying alphabetical outcomes.
          </div>
          <div className="p2">
            ISBN: 978-3-948440-35-0<br/>
            1st edition 2021
          </div>
          <div className="p2">
            Foreword: Paul McNeil<br/>
            Publishing Direction: Lars Harmsen, Julia Kahl<br/>
            Production: KOPA, Lithuania
          </div>

          <div className="price-link">
            <span>£35</span> <a href="https://www.slanted.de/product/letterform-variations/" target="_blank" rel="noreferrer">Buy book</a>
          </div>
        </div>


        <div className="block-content">
          <h2>
            Artwork
          </h2>

          <PhotoScroller folder="/artwork" prefix="artworks" start={1} length={25} extension="jpg" small={true} />

          <div className="p2">
            297x420mm
          </div>

          <div className="p2">
            Mechanically drawn onto archival paper using Posca paint marker.<br/>
            Each letterform is produced only once. All artworks are individual and unique, signed and numbered.<br/>
            If you would like to request a particular letterform please request via email <a href="mailto:nigel@acceptandproceed.com">here</a> or name the glyph in the Paypal form.
          </div>

          <div className="price-link">

            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="SA7A2M5K88UUG" />
              <span>£70+pp</span> <button className="button" type="submit">Buy Artwork</button>
            </form>
            
          </div>
        </div>


        <div className="block-content">
          <h2>
            Fonts
          </h2>

          <PhotoScroller folder="/font_img" prefix="font_" start={0} length={60} extension="jpg" small={true} />

          <div className="p2">
            The full collection of Letterform Variations.<br/>
            10 variable fonts all containing 16 alternatives for each roman symbol.<br/>
            Each containing a contextual alternates script to make the letterform selection feel random when typed. In total there are over 19,000 characters in this collection all variable between two weights.<br/>
            <a href="/pdf/PDFspecimens.zip" download>Download Specimens</a>
          </div>

          <div className="price-link">
            
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="AZNVAHUFFZ2MA" />
              <span>£80</span> <button className="button" type="submit">Buy Collection</button>
            </form>

          </div>

          <br/>
          <div className="p1">
            Individual fonts
          </div>

          <div className="price-list">
            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="VL6QXCQXYKPB6" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 00</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="3MGRMTTTGXLHQ" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 01 IR</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="MJ4Y3P3UY33LN" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 02 DFO</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="6MWSQLCFS8ADN" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 03 SFO</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="RN8XGPE78YTSL" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 04 DM</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="94KLBPM5K9GRE" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 05 DSFC</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="RT3469ZPW7ZH2" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 06 DFCD</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="R2QYCKQHECU3L" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 07 SFCS</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="ZB3W2Z7QXTLCG" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 08 DFCS</button>
              </form>
            </div>

            <div className="price-link">
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="E6GJL27XGDLYE" />
                <span>£20</span> <button className="button" type="submit">Buy Letterform Variations 09 SFCD</button>
              </form>
            </div>
          </div>
        </div>

        <div className="block-content lettersets">
          <h2>
            Lettersets
          </h2>

          {lv}

        </div>
      </div>    
    </Layout>
  )
}

export default BookPage
